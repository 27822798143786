<template>
    <div >
    <div class="row h-100 mt-3">
        <div class="col-4 border-end h-100">
            <h4>Kick Annotation Instructions</h4>
            <p>
                Please use the + key to go forward and - key to go back.
                Draw around the regions where there is a kick, then click the box to designate the player kicking.
                Once complete please click completed job
            </p>

            <div>
                <div class="d-flex mt-2" v-for="keyCode in Object.keys(hotkeys)" :key="keyCode">
                    <div style="height: 30px; width: 30px; border: 1px solid black; background: #c3c3c3; text-align: center; padding-top: 1px; border-radius: 5px;"> 
                        {{ manualMapping[keyCode] || String.fromCharCode(keyCode) }}
                    </div>
                    <div class="ms-2 my-auto">
                        {{ hotkeys[keyCode] }}
                    </div>
                </div>
            </div>

            <div>
                <button v-if="selectedViewpoint.kickAnnotationState == 'COMPLETE'" class="btn btn-warning mt-2" @click="markJob('INCOMPLETE')">Mark incomplete</button>
                <button v-else class="btn btn-success mt-2" @click="markJob('COMPLETE')">Complete Job</button>
            </div>

            <div class="m5-4" v-if="selectedViewpoint">
                <!-- <div>Tto annotated for this viewpoint:</div> -->
                
                <!-- <div class="progress">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :aria-valuenow="(((selectedViewpoint.totalFrames - frames.length) + framesRemaining) / selectedViewpoint.totalFrames) * 100" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': ((selectedViewpoint.totalFrames - framesRemaining) / selectedViewpoint.totalFrames) * 100 + '%' } "></div>
                </div> -->
            </div>
        </div>
        <div class="col-8" v-if="selectedFrame">
            <div class="d-flex">
                <div class="btn-group">
                    <a href="#" class="btn btn-primary active" aria-current="page" @click="previousFrame()">-</a>
                    <input v-model="selectedFrameIndex" type="number" class="form-control">
                    <a href="#" class="btn btn-primary" @click="nextFrame()">+</a>
                </div>
                <div class="my-auto     ms-4"> Total Frames: {{ selectedViewpoint.totalFrames }} </div>    
            </div>
            <div>Current frame ID: {{selectedFrame.id }}</div>
            <div>Annotations: {{ (selectedFrame.labels || []).join(',') }}</div>
            <div>
                <image-labeller ref="labeller" @boxes="setBoxes" :image="selectedFrame.url" />
            </div>
        </div>
        <div class="col-8" v-else-if="noMoreFramesToLoad">
            No more frames to annotate, thank you :)
        </div>
        <div class="col-8" v-else-if="loading">
            Loading
        </div>
        <div class="col-8" v-else>
            No frame loaded
        </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { getFrames, upsertFrame } from "@/api";
import ImageLabeller from "@/components/ImageLabeller"
// import { upsertViewpoint } from '../../api';
export default {
    data() {
        return {
            framesPage: 0,
            frames: [],
            selectedFrameIndex: 0,
            framesAnnotatedInSession: 0,
            noMoreFramesToLoad: false,
            loading: true,
            manualMapping: {
                107: "+",
                109: "-",

                187: "+",
                189: "-",
            },
            labels: [
                
            ],
            hotkeys: {
                187: 'NEXT',
                189: 'BACK',
            },
            hiddenHotkeys: {
                107: 'NEXT',
                109: 'BACK',
            },
            specialHandlers: {
                'NEXT': this.nextFrame,
                'BACK': this.previousFrame,
            },
        }
    },
    components: {
        ImageLabeller
    },
    async mounted() {
        console.log("Hi")
        if (!this.selectedViewpoint) {
            await this.$store.dispatch('getViewpoint', this.$route.params.id);
            this.$store.dispatch('selectViewpoint', this.$route.params.id);
        }

        console.log("Mounted");
        this.loadMoreFrames().then(x => {
            this.$refs.labeller.setBoxes(this.selectedFrame.kickRegions || [])
        });
        document.addEventListener('keydown', this.processHotkey);
    },
    destoryed() {

    },
    methods: {
        ...mapActions([ 'upsertViewpoint' ]),
        async setBoxes(boxes){  
            console.log("New box")
            this.selectedFrame.kickRegions = boxes;
        },
        async nextFrame() {
            console.log("Saving frame.")
            let frame = this.frames[this.selectedFrameIndex];
            upsertFrame(frame);
            this.selectedFrameIndex++;
            // this.$refs.labeller.boxes = frame.kickRegions;
        },
        async completeAnnotation() {
            this.selectedViewpoint.kickAnnotationState = 'COMPLETE'
            upsertViewpoint(this.selectedViewpoint)
        },
        async previousFrame() {
            this.selectedFrameIndex--;
            // this.$refs.labeller.boxes = this.selectedFrame.kickRegions;
            // this.$refs.labeller.boxes = [];
        },
        async processHotkey(e) {
            let keyCode = e.keyCode || e.which || e.code;

            // if (this.getFramesRemaining() < 10) {
            //     console.log("Frames remaining is low, loading more frames")
            //     this.loadMoreFrames();
            // }

            if (Object.keys(this.hotkeys).find(x => x == keyCode)) {
                console.log("Found label!");
                e.preventDefault();

                let label = this.hotkeys[keyCode];
                if (this.specialHandlers[label]) {
                    console.log("Got special handler");
                    return this.specialHandlers[label]();
                }
                
                if (!this.selectedFrame) return console.log("No frame");
                this.labelSelectedFrame(label);


                return true;
            }

            if (Object.keys(this.hiddenHotkeys).find(x => x == keyCode)) {
                console.log("Found label!");
                e.preventDefault();

                let label = this.hiddenHotkeys[keyCode];
                if (this.specialHandlers[label]) {
                    console.log("Got special handler");
                    return this.specialHandlers[label]();
                }
                
                if (!this.selectedFrame) return console.log("No frame");
                this.labelSelectedFrame(label);

                return true;
            }

            return;
        },
        async labelSelectedFrame(label) {
            let frame = this.frames[this.selectedFrameIndex];

            // frame.labels = [ label ]
            // frame.labels.push(label);
            this.$set(this.frames, this.selectedFrameIndex, frame);
        
            // upsertFrame(frame);
            console.log("Saved frame")

            this.framesAnnotatedInSession++;

            let firstFrameIndexNoLabels = this.frames.findIndex(x => !x.labels || x.labels.length == 0);
            this.selectedFrameIndex = firstFrameIndexNoLabels;
        },
        async loadMoreFrames() {
            console.log(`asking for more frames (page ${this.framesPage})`);
            // if (!this.selectedViewpoint) return console.log("No selected viewpoint watf?")
            let frames = await getFrames({
                viewpointId: this.$route.params.id,
                page: this.framesPage,
                sortBy: "frameNumber",
                sortDirection: "asc"
            });

            this.loading = false;

            this.framesPage++;
            this.frames = this.frames.concat(frames.data);
            console.log("Got more frames")

            if (frames.data.length == 0) {
                this.noMoreFramesToLoad = true;
                return console.log("No more frames");
            } else {
                this.loadMoreFrames();
                return console.log("Loading more frames");
            }

        },
        async markJob(state) {
            this.Swal.showLoading();
            this.selectedViewpoint.kickAnnotationState = state;

            try {
                await this.upsertViewpoint(this.selectedViewpoint.id);
            } catch (e) {
                return this.Swal.fire("Error", e.message, "error");
            }

            // return this.Swal.fire("Saved", "Complete", "success");
            this.Swal.close()
        },
    },
    computed: {
        ...mapGetters(['selectedViewpoint']),
        selectedFrame() { return this.frames[this.selectedFrameIndex] },
        boxes() { return this.frames[this.selectedFrameIndex].boxes },
       
        getFramesRemaining() {
            let framesAnnotatedRemaining = 0;
            for (let frame of this.frames) {
                // debugger;
                if (frame.labels && frame.labels.length > 0) continue; // it has been annotated.
                framesAnnotatedRemaining++; 
            }
            // console.log(`found ${framesAnnotatedRemaining} frames left to annotate`)
            return framesAnnotatedRemaining;
        },
    },
    watch: {
        selectedFrameIndex() {
            // We need to set the bo
            if (!this.$refs.labeller) {
                console.error('No labeller ref?!!');
                return
            }
            console.log("Set boxes")
            this.$refs.labeller.setBoxes(this.selectedFrame.kickRegions || [])

        },
    }
}
</script>

<style>
</style>